import React from 'react'
import Img from'gatsby-image'
import BackgroundImage from "gatsby-background-image"
import SignupForm from './signupForm'


const ConditionalWrapper = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;

const DownloadPageContent = (props) => {
  // swap for static image on preview
  const imageOneSelector =  props.firstCover
  ? <Img fixed={props.firstCover} alt='Free Book' /> 
  : <img 
      src={props.previewFirstCover} 
      style={{
        width: 200,
        height: 300,
      }}
      alt='Free Book' />
  const imageTwoSelector =  props.secondCover
  ? <Img fixed={props.secondCover} alt='Free Book' /> 
  : <img 
      src={props.previewSecondCover} 
      style={{
        width: 200,
        height: 300,
      }}
      alt='Free Book' />
  return(
    <ConditionalWrapper 
    condition={props.backgroundImage}
    wrapper={children => <BackgroundImage fluid={props.backgroundImage} style={{flexGrow: 1}}>{children}</BackgroundImage>}>
      <div className="container section">
        <div className="row">
          <div className='col-lg-8 text-center' style={{marginBottom: 45}}>
              <h2 style={{color : props.titleColor}}>{props.title}</h2>
            <div className='row'>
              <div className='col-sm-6 d-flex'>
                {imageOneSelector}
              </div>
              <div className='col-sm-6 d-flex'>
                {imageTwoSelector}
              </div>
            </div>
            <div className="row text-left" style={{padding: 15}}>
              <div dangerouslySetInnerHTML={{ __html: props.body}} />
              {props.previewBody}
            </div>
            
          </div>
          <div className="col-lg-4 readersClub">
                <SignupForm />
            <div className="row">

            </div>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}

export default DownloadPageContent