import React, { useEffect } from 'react';

const SignupForm = () => {
  useEffect(() => {
    const mailerliteJS = `
    function ml_webform_success_2479837(){
      var r=ml_jQuery||jQuery;
      r(".ml-subscribe-form-2479837 .row-success").show(),
      r(".ml-subscribe-form-2479837 .row-form").hide()
    }
    `
    const script = document.createElement("script");
    const scriptText = document.createTextNode(mailerliteJS);
    script.appendChild(scriptText);
    document.body.appendChild(script);
  
    const script2 = document.createElement("script");
    script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?v481a88ede9b3bab30890bb64084ef6e8";
    document.body.appendChild(script2);
  });
  return(
    <div>
      <div id="mlb2-2479837" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-2479837">
        <div className="card bg-dark row-form">
          <div className="card-body">
            <div className="card-title">YOUR FREE STORIES</div>
            <p>Enter your email address to get your free stories, click Subscribe, and you'll be added to my Readers' Club. I email roughly once a month with news and chat about books and writing. I will not pass your address to anyone else, nor will I spam you. You can unsubscribe anytime</p>
            <form 
              className="ml-block-form" 
              action="https://static.mailerlite.com/webforms/submit/v3r9k5" 
              data-code="v3r9k5" 
              method="post" 
              target="_blank">
              <div className="form-group">
                <input type="email" className="form-control" data-inputmask="" name="fields[email]" placeholder="Email" autocomplete="email" />
              </div>
              <input type="hidden" name="ml-submit" value="1" />
              <div class="ml-form-embedSubmit">
                <button type="submit" className="btn btn-primary primary">Subscribe</button>
                <button disabled="disabled" style={{display:'none'}} type="button" className="loading btn btn-primary">Submitting...</button>
              </div>
            </form>
          </div>
        </div>
        <div className="alert alert-success row-success" style={{display: 'none' }}>
          <h4>THANK YOU!</h4>
          <p>An email is on its way explaining how to get your free story. Please check your Inbox - and also your Junk Box or Promo tab if necessary. Then you can get your FREE STORY</p>
        </div>
      </div>
      <img src="https://track.mailerlite.com/webforms/o/2479837/v3r9k5?v1606225777" width="1" height="1" style={{
        maxWidth:1,
        maxHeight:1,
        visibility:'hidden',
        padding:0,
        margin:0,
        display:'block'
      }} alt="." border="0" />
    </div>
  )
}

export default SignupForm