import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from './components/layout'
import Head from './components/head'
import DownloadPageContent from './components/downloadPageContent'

const DownloadPage = () => {
    const data = useStaticQuery(graphql`
  query downloadPageData {
    downloadPage: markdownRemark(frontmatter: {templateKey: {eq: "free-download"}}) {
      html
      frontmatter {
        title
        color
        backgroundImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        firstCover {
          childImageSharp {
            fixed(height: 400){
              ...GatsbyImageSharpFixed
            }
          }
        }
        secondCover {
          childImageSharp {
            fixed(height: 400){
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <Head title="Free Download"/>
      <DownloadPageContent 
        backgroundImage={data.downloadPage.frontmatter.backgroundImage.childImageSharp.fluid}
        firstCover={data.downloadPage.frontmatter.firstCover.childImageSharp.fixed}
        secondCover={data.downloadPage.frontmatter.secondCover.childImageSharp.fixed}
        titleColor={data.downloadPage.frontmatter.color}
        title={data.downloadPage.frontmatter.title}
        body={data.downloadPage.html}
      />
    </Layout>
  )
}

export default DownloadPage